@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

textarea:hover, 
input:hover, 
textarea:active, 
input:active, 
textarea:focus, 
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
    outline:0px !important;
    -webkit-appearance:none;
    box-shadow: none !important;
}

/* Changes the scrollbar track or the progress bar below */
::-webkit-scrollbar {
  width: 3px;
}

/* Changes the scrollbar thumb or the draggable scrolling handle */
::-webkit-scrollbar-thumb {
  @apply bg-neutral-700
}

/* Changes the scrollbar thumb when it's hovered */
::-webkit-scrollbar-thumb:hover {
  background: blue; 
}

html *:not(.show-scrollbar)::-webkit-scrollbar {
  display: none;
}

.show-scrollbar::-webkit-scrollbar {
  display: initial; /* Or set it to whatever value you want */
}


html *:not(.show-scrollbar) {
  scrollbar-width: none;  /* Firefox 64 */
}

.show-scrollbar {
  scrollbar-width: auto; /* Or set it to whatever value you want */
}

/* Always show scrollbar */
body {
  overflow-y: scroll;
  @apply bg-light-backdrop dark:bg-dark-backdrop
}

.Root {
  height: 60vh;
}

.post-follow-btn {
  @apply px-3 py-2 max-h-5 drop-shadow-[3px_3px_5px_rgba(0,0,0,0.35)] transition ease-in-out duration-300 rounded-xl leading-snug whitespace-nowrap text-base font-semibold text-white bg-primary hover:bg-green-500;;
}

.post {
  @apply justify-items-center;
}

.btn-primary {
  @apply text-white bg-green-500 hover:bg-green-600;
}

@keyframes borderAnimation {
  0% {
    @apply border-amber-100
  }
  100% {
    @apply border-amber-600
  }
}

.animated-border {
  animation: borderAnimation 1s infinite alternate; /* Animation duration and iteration */
}
